import cookie from 'vue-cookie';

const helper = vue => ({
  setToken(uuid) {
    if (uuid) {
      cookie.set('uuid', uuid, { expires: '8h' });
    } else {
      cookie.delete('uuid');
    }
  },
  getToken() {
    return cookie.get('uuid');
  },

  async get(url, query) {
    try {
      let qs = query;
      const hash = cookie.get('uuid') || false;
      const headers = {};
      if (hash) {
        headers['X-SESSION'] = hash;
      }
      const response = await vue.http.get(url, { params: qs, headers });
      response.success = true;
      return response;
    } catch (err) {
      err.success = false;
      return err;
    }
  },
  async post(url, data, isMultipart = false) {
    try {
      const headers = {};
      const hash = cookie.get('uuid') || false;
      if (hash) {
        headers['X-SESSION'] = hash;
      }
      if (isMultipart) {
        headers['Content-Type'] = 'multipart/form-data';
      }
      const response = await vue.http.post(
        url,
        data,
        { headers },
      );
      response.success = true;
      return response;
    } catch (err) {
      err.success = false;
      return err;
    }
  },
  async put(url, data, isMultipart = false) {
    try {
      const headers = {};
      const hash = cookie.get('uuid') || false;
      if (hash) {
        headers['X-SESSION'] = hash;
      }
      if (isMultipart) {
        headers['Content-Type'] = 'multipart/form-data';
      }
      const response = await vue.http.put(
        url,
        data,
        { headers },
      );
      response.success = true;
      return response;
    } catch (err) {
      err.success = false;
      return err;
    }
  },
  async delete(url) {
    try {
      const headers = {};
      const hash = cookie.get('uuid') || false;
      if (hash) {
        headers['X-SESSION'] = hash;
      }
      const response = await vue.http.delete(url, { headers });
      response.success = true;
      return response;
    } catch (err) {
      err.success = false;
      return err;
    }
  },
});

export default {
  install(Vue) {
    /* eslint-disable */
    Vue.prototype.$test = () => true;
    Object.assign(Vue.prototype, { $webHelper: helper(Vue) });
  },
};
