var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-slide-y-transition',{attrs:{"hide-on-leave":"","leave-absolute":""}},[_c('v-flex',{staticClass:"mx-2",attrs:{"xs12":""}},[_c('h2',{staticClass:"mb-2"},[_vm._v("Providers")]),(_vm.err)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-alert',{attrs:{"value":true,"type":"error"}},[_vm._v(_vm._s(_vm.err))])],1):_vm._e(),_c('v-card-title',{staticClass:"pb-4 px-0 layout row wrap align-end"},[(!_vm.life)?_c('v-btn',{staticClass:"primary mx-0 my-0",on:{"click":function($event){return _vm.$router.push({ name: 'providerCreate'})}}},[_vm._v("Create New "+_vm._s(_vm.provider))]):_vm._e(),_c('v-spacer'),_c('v-text-field',{staticClass:"min-w max-w",attrs:{"append-icon":"mdi-search","label":"Search","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"loading":_vm.loading,"items-per-page-options":[10, 25, 50, 100, { text:'All' , value: -1 }]},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"table-min-w"},[_vm._v(_vm._s(item.name))])]}},{key:"item.trustRanking",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.trustRanking))])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"table-min-w"},[_vm._v(_vm._s(item.url))])]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-switch',{attrs:{"hide-details":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changeStatus(item)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-btn',{staticClass:"accent mx-2 elevation-1",on:{"click":function($event){return _vm.deleteProvider(item.id)}}},[_vm._v("delete")]),_c('v-btn',{staticClass:"primary mx-0 elevation-1",on:{"click":function($event){return _vm.$router.push({ name: 'providerDetails', params: { id: item.id } })}}},[_vm._v("view")])],1)]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","height":"6","color":"primary","indeterminate":""},slot:"progress"}),_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"warning","icon":"warning"},slot:"no-results"},[_vm._v(" Your search for \""+_vm._s(_vm.search)+"\" found no results. ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }