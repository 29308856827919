var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-slide-y-transition',{attrs:{"hide-on-leave":"","leave-absolute":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card-title',{staticClass:"layout row wrap align-end"},[_c('h2',[_vm._v("Life products")]),_c('v-spacer'),(_vm.rates.length > 0)?_c('v-text-field',{staticClass:"min-w max-w",attrs:{"append-icon":"mdi-search","label":"Search","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),(_vm.rates.length > 0)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rates,"search":_vm.search,"items-per-page-options":[10, 25, 50, 100, { text:'All' , value: -1 }]},scopedSlots:_vm._u([{key:"item.age",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.age))])]}},{key:"item.menNonSmoker",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s((parseFloat(item.menNonSmoker) * 100).toFixed(3))+"%")])]}},{key:"item.menSmoker",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s((parseFloat(item.menSmoker) * 100).toFixed(3))+"%")])]}},{key:"item.womenNonSmoker",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s((parseFloat(item.womenNonSmoker) * 100).toFixed(3))+"%")])]}},{key:"item.womenSmoker",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s((parseFloat(item.womenSmoker) * 100).toFixed(3))+"%")])]}}],null,false,276267016)},[_c('v-progress-linear',{attrs:{"slot":"progress","height":"6","color":"primary","indeterminate":""},slot:"progress"}),_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"warning","icon":"warning"},slot:"no-results"},[_vm._v(" Your search for \""+_vm._s(_vm.search)+"\" found no results. ")])],1):_vm._e(),(_vm.rates.length > 0)?_c('hr'):_vm._e(),(_vm.err)?_c('v-flex',{attrs:{"xs12":"","pb-3":""}},[_c('v-alert',{attrs:{"value":true,"type":"error"}},[_vm._v(_vm._s(_vm.err))])],1):_vm._e(),(_vm.success)?_c('v-flex',{attrs:{"xs12":"","pb-3":""}},[_c('v-alert',{attrs:{"value":true,"type":"success"}},[_vm._v(_vm._s(_vm.success))])],1):_vm._e(),_c('dropzone',{attrs:{"text":"Drop CSV file or click to upload it"},on:{"upload":function($event){_vm.rateFile = $event},"remove":function($event){_vm.rateFile = null}}}),_c('v-btn',{staticClass:"mx-0",attrs:{"color":"primary","disabled":!_vm.rateFile},on:{"click":_vm.upload}},[_vm._v("Upload")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }