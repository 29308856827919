<template>
  <v-app light>
    <router-view />
  </v-app>
</template>
<script>
export default {
  name: 'app',
  mounted() {
    this.$router.beforeEach((to, from, next) => {
      if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!this.$webHelper.getToken()) {
          next({ name: 'login' });
        } else {
          next();
        }
      } else {
        next();
      }
    });
    if (!this.$webHelper.getToken()) {
      this.$router.push({ name: 'login' });
    }
  },
};
</script>

<style>
body {
  margin: 0;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: #fff;
  overflow-x: hidden;
}
html {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto !important;
}
.min-w {
  min-width: 140px;
}
.max-w {
  max-width: 240px;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.table-min-w {
  min-width: 200px;
}
.v-input .v-label {
  z-index: 2;
}
input,
input[type="email"],
input:-webkit-autofill,
input[type="email"]:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background: #fff !important;
}
input:-webkit-autofill,
input[type="email"]:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
</style>
