<template>
  <v-layout align-center justify-center class="login">
    <v-card class="pa-4 login-card elevation-5">
      <v-form v-model="valid">
        <v-text-field
          class="py-1"
          type="password"
          v-model="password"
          label="Password"
          hint="At least 4 characters"
          @click:append="show = !show"
          :rules="[rules.required, rules.min]"
        />
        <v-text-field
          class="py-1"
          type="password"
          v-model="repassword"
          label="Repeat password"
          hint="At least 4 characters"
          @click:append="show = !show"
          :rules="[rules.required, rules.min]"
        />
        <v-btn class="mx-0 primary" @click="submit" :disabled="!valid">Submit</v-btn>
      </v-form>
    </v-card>
  </v-layout>
</template>

<script>
  export default {
    data: () => ({
      valid: false,
      repassword: '',
      password: '',
      show: false,
      rules: {
        required: v => !!v || 'Required.',
        min: v => v.length >= 4 || 'Min 4 characters',
      },
    }),
    methods: {
      async submit() {
        if (this.password !== this.repassword) {
          this.err = 'Passwords do not match';
          return;
        }
        this.err = '';

        const res = await this.$webHelper.post('user/change-password', { password: this.password });
        if (!res.success) {
          this.err = 'Failed to change password.';
          return;
        }
        this.$router.push('/providers');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .login {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }
  .login-card {
    width: 100%;
    min-width: 300px;
    max-width: 400px;
  }
  .login-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    opacity: 0.3;
    -webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
    filter: blur(5px);
  }
</style>
