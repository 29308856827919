<template>
  <v-layout align-center justify-center class="login">
    <img class="login-bg" src="~@/assets/sidebar.jpg" alt="">
    <v-card class="pa-4 login-card elevation-0">
      <img class="d-flex align-center mx-auto pb-4 login-logo" src="~@/assets/logo-color.png" alt="">
      <v-form v-model="valid">
        <v-text-field
          class="py-1"
          type="email"
          v-model="email"
          label="Email"
          :rules="[rules.required]"
        />
        <v-text-field
          class="py-1"
          :type="show ? 'text' : 'password'"
          v-model="password"
          label="Password"
          :append-icon="show ? 'mdi-visibility-off' : 'mdi-visibility'"
          hint="At least 4 characters"
          @click:append="show = !show"
          :rules="[rules.required, rules.min]"
        />
        <v-btn class="mx-0 primary" @click="submit" :disabled="!valid">Login</v-btn>
      </v-form>
    </v-card>
  </v-layout>
</template>

<script>
  export default {
    data: () => ({
      valid: false,
      email: '',
      password: '',
      show: false,
      rules: {
        required: v => !!v || 'Required.',
        min: v => v.length >= 4 || 'Min 4 characters',
      },
    }),
    methods: {
      async submit() {
        this.err = '';
        const res = await this.$webHelper.post('login', {
          email: this.email,
          password: this.password,
        });
        if (!res.success) {
          this.err = 'Login failed. Please try again';
          return;
        }
        this.$webHelper.setToken(res.body.sessionHash);
        this.$router.push({ name: 'providers' });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .login {
    width: 100%;
    height: 100vh;
    background-color: #26a69a;
    overflow: hidden;
    position: relative;
  }
  .login-card {
    width: 100%;
    min-width: 300px;
    max-width: 400px;
  }
  .login-logo {
    width: 250px;
  }
  .login-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    opacity: 0.3;
    -webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
    filter: blur(5px);
  }
</style>
