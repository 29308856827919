<template>
  <v-slide-y-transition
    hide-on-leave 
    leave-absolute>
    <v-flex xs12>

      <v-card-title class="layout row wrap align-end"> 
        <h2>Life products</h2>
        <v-spacer />
        <v-text-field
          v-if="rates.length > 0"
          class="min-w max-w"
          v-model="search"
          append-icon="mdi-search"
          label="Search"
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        v-if="rates.length > 0"
        :items="rates"
        :search="search"
        :items-per-page-options="[10, 25, 50, 100, { text:'All' , value: -1 }]"
      >
        <v-progress-linear height="6" slot="progress" color="primary" indeterminate />
        <template v-slot:item.age="{ item }">
          <td>{{ item.age }}</td>
        </template>
        <template v-slot:item.menNonSmoker="{ item }">
          <td>{{ (parseFloat(item.menNonSmoker) * 100).toFixed(3) }}%</td>
        </template>
        <template v-slot:item.menSmoker="{ item }">
          <td>{{ (parseFloat(item.menSmoker) * 100).toFixed(3) }}%</td>
        </template>
        <template v-slot:item.womenNonSmoker="{ item }">
          <td>{{ (parseFloat(item.womenNonSmoker) * 100).toFixed(3) }}%</td>
        </template>
        <template v-slot:item.womenSmoker="{ item }">
          <td>{{ (parseFloat(item.womenSmoker) * 100).toFixed(3) }}%</td>
        </template>
        <v-alert slot="no-results" :value="true" color="warning" icon="warning">
          Your search for "{{ search }}" found no results.
        </v-alert>
      </v-data-table>
      <hr v-if="rates.length > 0">
      <v-flex xs12 pb-3 v-if="err">
        <v-alert :value="true" type="error">{{err}}</v-alert>
      </v-flex>
      <v-flex xs12 pb-3 v-if="success">
        <v-alert :value="true" type="success">{{success}}</v-alert>
      </v-flex>
      <dropzone
        text="Drop CSV file or click to upload it"
        @upload="rateFile = $event"
        @remove="rateFile = null" />
      <v-btn color="primary" :disabled="!rateFile" class="mx-0" @click="upload">Upload</v-btn>
    </v-flex>
  </v-slide-y-transition>
</template>

<script>
  export default {
    data: () => ({
      err: '',
      success: '',
      headers: [
        { text: 'Age', value: 'age', align: 'left' },
        { text: 'Men NS', value: 'menNonSmoker', align: 'left' },
        { text: 'Men S', value: 'menSmoker', align: 'left' },
        { text: 'Women NS', value: 'womenNonSmoker', align: 'left' },
        { text: 'Women S', value: 'womenSmoker', align: 'left' },
      ],
      rates: [],
      rateFile: null,
      search: '',
    }),
    components: {
      dropzone: () => import('@/components/Dropzone'),
    },
    watch: {
      err(val) {
        if (val !== '') {
          setTimeout(() => { this.err = ''; }, 5000);
        }
      },
      success(val) {
        if (val !== '') {
          setTimeout(() => { this.success = ''; }, 3000);
        }
      },
    },
    methods: {
      async upload() {
        const formData = new FormData();
        formData.set('premium', this.rateFile);
        const res = await this.$webHelper.post('product/life', formData, true);
        if (!res.success) {
          this.err = 'Failed to upload.';
          return;
        }
        this.success = 'Upload complete';
        this.rateFile = null;
        this.getProducts();
      },
      async getProducts() {
        const res = await this.$webHelper.get('product/life');
        if (!res.success) {
          this.err = 'Failed to fetch Life products';
          return;
        }
        this.rates = res.body;
      },
    },
    created() {
      this.getProducts();
    },
  };
</script>

<style>

</style>
