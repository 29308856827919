import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/components/Login';
import Layout from '@/components/Layout';
import ErrorPage from '@/components/404';

import ChangePassword from '@/components/ChangePassword';
import Providers from '@/components/providers/Providers';
import ProviderDetails from '@/components/providers/ProviderDetails';

import ProductDetails from '@/components/products/ProductDetails';
import Products from '@/components/products/Products';
import LifeProduct from '@/components/products/LifeProduct';

Vue.use(Router);

export default new Router({
  // base: '/admin',
  routes: [
    {
      path: '/login',
      component: Login,
      name: 'login',

    },
    {
      path: '/',
      component: Layout,
      redirect: { name: 'providers' },
      children: [
        {
          path: 'change-password',
          component: ChangePassword,
          name: 'changePassword',
          meta: { requiresAuth: true },
        },
        {
          path: 'providers',
          component: Providers,
          name: 'providers',
          meta: { requiresAuth: true },
        },
        {
          path: 'provider/:id/details',
          component: ProviderDetails,
          name: 'providerDetails',
          meta: { requiresAuth: true },
        },
        {
          path: 'provider/:providerId/products/create',
          component: ProductDetails,
          name: 'productCreateForProvider',
          meta: { requiresAuth: true },
        },
        {
          path: 'provider/:providerId/products/:productId',
          component: ProductDetails,
          name: 'providerProductDetails',
          meta: { requiresAuth: true },
        },
        {
          path: 'provider/create',
          component: ProviderDetails,
          name: 'providerCreate',
          meta: { requiresAuth: true },
        },
        {
          path: 'provider/:id/edit',
          component: ProviderDetails,
          name: 'providerEdit',
          meta: { requiresAuth: true },
        },
        {
          path: '/products',
          component: Products,
          name: 'products',
          meta: { requiresAuth: true },
        },
        {
          path: '/products/create',
          component: ProductDetails,
          name: 'productCreate',
          meta: { requiresAuth: true },
        },
        {
          path: '/products/:productId',
          component: ProductDetails,
          name: 'productDetails',
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: '/life-insurance',
      component: Layout,
      children: [
        {
          path: '/',
          component: LifeProduct,
          name: 'lifeInsurance',
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: '/*',
      component: ErrorPage,
      name: '404',
    },
  ],
  mode: 'history',
});
