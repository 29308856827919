import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Cookie from 'vue-cookie';
import Resource from 'vue-resource';
import WebHelper from './helpers/web'

Vue.config.productionTip = false

Vue.use(Resource);
Vue.http.options.root = '/api/v1';

Vue.use(Cookie);
Vue.use(WebHelper);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
