<template>
  <div>
    <v-navigation-drawer
      class="sidebar primary"
      app
      fixed
      clipped
      v-model="drawer"
      floating
      :hide-overlay="!isMobile"
      :permanent="!isMobile"
      width="260"
    >
      <div class="logo pt-5 pb-4 mx-4">
        <img class="pl-1 pr-2" src="~@/assets/logo.png" alt="">
      </div>
      <v-layout column justify-space-between>
        <v-list class="py-4">
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.to"
          >
            <v-list-item-action>
              <v-icon class="white--text">{{ item.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title class="white--text">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div class="separator mx-4"></div>
          <v-list-item to="/change-password">
            <v-list-item-action>
              <v-icon class="white--text">mdi-lock</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="white--text">Change password</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="logout()">
            <v-list-item-action>
              <v-icon class="white--text">mdi-exit-to-app</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="white--text">Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-layout>
      <img class="bg-img" src="~@/assets/sidebar.jpg" alt="">
    </v-navigation-drawer>
    <v-toolbar v-if="isMobile" app fixed clipped-left dark class="elevation-1">
      <v-toolbar-side-icon @click.stop="drawer = !drawer"/>
      <v-toolbar-title>QRS</v-toolbar-title>
    </v-toolbar>
    <v-main>
      <v-container fluid fill-he>
        <router-view :key="$route.fullPath"/>
      </v-container>
    </v-main>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isMobile: false,
        drawer: true,
        items: [
          { title: 'Providers', icon: 'mdi-view-list', to: { name: 'providers' } },
          { title: 'Products', icon: 'mdi-view-list', to: { name: 'products' } },
          { title: 'Life Insurance', icon: 'mdi-view-list', to: { name: 'lifeInsurance' } },
        ],
      };
    },
    mounted() {
      this.onResize();
      window.addEventListener('resize', this.onResize, { passive: true });
    },
    methods: {
      async logout() {
        this.$webHelper.setToken(undefined);
        this.$router.push({ name: 'login' });
      },
      onResize() {
        if (window.innerWidth < 762) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
          this.drawer = true;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sidebar {
    .bg-img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      opacity: 0.2;
      z-index: -1;
    }
  }
  .v-list {
    background-color: transparent;
  }
  .separator {
    display: block;
    border-bottom: 1px solid rgba(#fff, 0.6);
    padding-top: 15px;
    margin-bottom: 15px;
  }
  .logo {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    border-bottom: 1px solid rgba(#fff, 0.6);
    img {
      align-self: center;
      width: 100%;
      height: auto;
    }
  }
</style>
