<template>
  <v-slide-y-transition
    hide-on-leave 
    leave-absolute>
    <v-flex xs12>
      <h2 class="mb-2 px-3">
        Product
        <v-btn
          color="primary"
          fab
          small
          class="elevation-0"
          @click="goBack()">
          <v-icon>mdi-arrow-back-ios</v-icon>
        </v-btn>
      </h2>
      <v-flex xs12 pb-3 px-3 v-if="err">
        <v-alert :value="true" type="error">{{err}}</v-alert>
      </v-flex>
      <v-flex xs12 pb-3 px-3 v-if="success">
        <v-alert :value="true" type="success">{{success}}</v-alert>
      </v-flex>
      <v-form v-model="valid" @submit.prevent="submit">
        <v-layout row wrap>
          <v-flex xs12 px-3 mb-2>
            <v-text-field
              label="Name"
              type="text"
              :rules="[v => !!v || 'Name is required']"
              v-model="name"
            />
          </v-flex>
          <v-flex xs12 px-3 mb-2>
            <v-text-field
              label="Area of Cover"
              type="text"
              :rules="[v => !!v || 'Area of cover is required']"
              v-model="healthInsurance.areaOfCover"
            />
          </v-flex>
          <v-flex xs12 px-3 mb-2>
            <v-text-field
              label="Local Nationals Accepted"
              type="text"
              :rules="[v => !!v || 'Local Nationals Accepted is required']"
              v-model="healthInsurance.localNationalsAccepted"
            />
          </v-flex>
          <v-flex xs12 md6 px-3 mb-2>
            <v-autocomplete 
              v-model="provider"
              :items="providers"
              :disabled="!!$route.params.providerId || isEdit"
              :rules="[v => !!v || 'Provider is required']"
              item-value="id"
              item-text="name"
              label="Select provider"
              persistent-hint
              color="primary"
            />
          </v-flex>
          <v-flex xs12 md6 px-3 mb-2>
            <v-autocomplete 
              v-model="type"
              :items="types"
              :disabled="isEdit"
              label="Type"
              hint="Select product type in order to display input fields"
              persistent-hint
              color="primary"
            />
          </v-flex>
          <v-flex xs12 md6 px-3 mb-2>
            <v-text-field
              label="Preferred Product Order"
              type="number"
              hint="If added, preferred product order overrides default product order"
              :rules="[v => (!v || !isNaN(v)) || 'Rank must be a number']"
              v-model="ranking"
            />
          </v-flex>
          <v-flex xs12 sm8 md6 px-3>
            <v-checkbox
              label="Activate"
              v-model="isActive"
            />
          </v-flex>

          <v-flex xs12 px-3 v-if="isEdit">
            <v-checkbox
              label="Update brochure?"
              v-model="updateBrochure"
            />
          </v-flex>
          <v-flex xs12 px-3 v-if="!isEdit || updateBrochure">
            <dropzone
              text="Drop Brochure file or click to upload it"
              :type="['application/pdf']"
              :hidePreview="true"
              @upload="brochure = $event"
              @remove="brochure = null" />
          </v-flex>
        </v-layout>
        <v-flex xs12 px-3 v-if="type"><hr></v-flex>
        <v-slide-y-transition mode="out-in" group>
          <v-layout row wrap v-if="type === 'health'" key="health">
            <v-flex xs12 sm8 md6 px-3>
              <v-text-field
                label="Plan limit"
                type="number"
                v-model="healthInsurance.limit"
              />
            </v-flex>
            <v-flex xs12 sm8 md6 px-3>
              <v-text-field
                label="Plan limit"
                type="number"
                v-model="healthInsurance.limit"
              />
            </v-flex>
            <v-flex xs12 sm8 md6 px-3>
              <v-combobox 
                v-model="healthInsurance.inpatient"
                :items="options"
                :search-input.sync="healthInsurance.inpatient"
                :rules="healthOptionRules"
                label="Inpatient"
                clearable
              />
            </v-flex>
            <v-flex xs12 sm8 md6 px-3>
              <v-combobox 
                v-model="healthInsurance.outpatient"
                :items="options"
                :search-input.sync="healthInsurance.outpatient"
                :rules="healthOptionRules"
                label="Outpatient"
                clearable
              />
            </v-flex>
            <v-flex xs12 sm8 md6 px-3>
              <v-combobox 
                v-model="healthInsurance.dental"
                :items="options"
                :search-input.sync="healthInsurance.dental"
                :rules="healthOptionRules"
                label="Dental"
                clearable
              />
            </v-flex>
            <v-flex xs12 sm8 md6 px-3>
              <v-combobox 
                v-model="healthInsurance.maternity"
                :items="options"
                :search-input.sync="healthInsurance.maternity"
                :rules="healthOptionRules"
                label="Maternity"
                clearable
              />
            </v-flex>
            <v-flex xs12 sm8 md6 px-3>
              <v-autocomplete
                v-if="countries"
                v-model="healthInsurance.locations"
                :items="countries"
                style="max-height: 200px;min-height: 45px;overflow: auto;"
                class="mb-3"
                item-value="name"
                item-text="name"
                hide-details
                multiple
                small-chips
                deletable-chips
                label="Select locations"
              />
              <v-btn
                class="elevation-0"
                color="white"
                @click="healthInsurance.locations = countries.map(country => country.name)">Select all</v-btn>
              <v-btn
                class="elevation-0"
                color="white"
                @click="updateCountries([])">Select none</v-btn>

                <v-btn
                class="elevation-0"
                color="white"
                @click="updateCountries(['Albania','Andorra','Austria','Belarus','Belgium','Bosnia and Herzegovina','Bulgaria','Croatia','Cyprus','Czech Republic','Denmark','Estonia','Finland','France','Germany','Greece','Hungary','Iceland','Ireland','Italy','Kosovo','Latvia','Liechtenstein','Lithuania','Luxembourg','Malta','Moldova','Monaco','Montenegro','Netherlands','North Macedonia','Norway','Poland','Portugal','Romania','Russia','San Marino','Serbia','Slovakia','Slovenia','Spain','Sweden','Switzerland','Ukraine','United Kingdom','Vatican City'])">Europe</v-btn>
                
                <v-btn
                class="elevation-0"
                color="white"
                @click="updateCountries(['Brunei','Cambodia','East Timor (Timor-Leste)','Indonesia','Laos','Malaysia','Myanmar (Burma)','Philippines','Singapore','Thailand','Vietnam'])">South East Asia</v-btn>
                

                <v-btn
                class="elevation-0"
                color="white"
                @click="updateCountries(['Afghanistan','Armenia','Azerbaijan','Bahrain','Bangladesh','Bhutan','Brunei','Cambodia','China','Cyprus','East Timor (Timor-Leste)','Georgia','India','Indonesia','Iran','Iraq','Israel','Japan','Jordan','Kazakhstan','Kuwait','Kyrgyzstan','Laos','Lebanon','Malaysia','Maldives','Mongolia','Myanmar (Burma)','Nepal','North Korea','Oman','Pakistan','Palestine','Philippines','Qatar','Russia','Saudi Arabia','Singapore','South Korea','Sri Lanka','Syria','Taiwan','Tajikistan','Thailand','Turkey','Turkmenistan','United Arab Emirates','Uzbekistan','Vietnam','Yemen','Australia','New Zealand','Papua New Guinea','Solomon Islands','Vanuatu','Kiribati','Marshall Islands','Nauru','Palau','Cook Islands','French Polynesia','Niue','Samoa','Tonga','Tuvalu','Wallis and Futuna'])"> Asia</v-btn>
                
                <v-btn
                class="elevation-0"
                color="white"
                @click="updateCountries(['Algeria','Angola','Benin','Botswana','Burkina Faso','Burundi','Cabo Verde','Cameroon','Central African Republic','Chad','Comoros','Congo, Democratic Republic of the','Congo, Republic of the','Cote d\'Ivoire (Ivory Coast)','Djibouti','Egypt','Equatorial Guinea','Eritrea','Eswatini (formerly Swaziland)','Ethiopia','Gabon','Gambia','Ghana','Guinea','Guinea-Bissau','Kenya','Lesotho','Liberia','Libya','Madagascar','Malawi','Mali','Mauritania','Mauritius','Morocco','Mozambique','Namibia','Niger','Nigeria','Rwanda','Sao Tome and Principe','Senegal','Seychelles','Sierra Leone','Somalia','South Africa','South Sudan','Sudan','Tanzania','Togo','Tunisia','Uganda','Zambia','Zimbabwe'])"> Africa</v-btn>
         

                <v-btn
                class="elevation-0"
                color="white"
                @click="updateCountries(['Canada', 'United States of America','Mexico'])"> North America</v-btn>

                <v-btn
                class="elevation-0"
                color="white"
                @click="updateCountries(['Belize','Costa Rica','El Salvador','Guatemala','Honduras','Nicaragua','Panama'])"> Central America</v-btn>
              

                
                <v-btn
                class="elevation-0"
                color="white"
                @click="updateCountries(['Antigua and Barbuda','Bahamas','Barbados','Cuba','Dominica','Dominican Republic','Grenada','Haiti','Jamaica','Saint Kitts and Nevis','Saint Lucia','Saint Vincent and the Grenadines','Trinidad and Tobago'])">Caribbean </v-btn>
         
                
                <v-btn
                class="elevation-0"
                color="white"
                @click="updateCountries(['Argentina','Bolivia','Brazil','Chile','Colombia','Ecuador','Guyana','Paraguay','Peru','Suriname','Uruguay','Venezuela'])">South America </v-btn>
         
                


                
            </v-flex>
            <v-checkbox
              v-if="isEdit"
              class="mx-3"
              :class="{ 'mb-3' : !dropArea }"
              label="Do you want to update premiums"
              v-model="dropArea"
              hide-details
            />
            <v-flex xs12 px-3 mt-3 v-if="dropArea || !isEdit">
              <dropzone
                text="Drop CSV file or click to upload it"
                @upload="setHealthPremiums($event)"
                @remove="setHealthPremiums(null)" />
              <v-btn color="primary" v-if="premiumPreview" @click="previewHealth">Preview</v-btn>
            </v-flex>
            <v-flex xs12 px-3>
              <v-checkbox
                v-if="isEdit"
                label="Update application form?"
                v-model="updateApplicationForm"
              />
            </v-flex>
            <v-flex xs12 px-3 mt-3 v-if="!isEdit || updateApplicationForm">
              <dropzone
                text="Drop Application form file or click to upload it"
                :type="['application/pdf']"
                :hidePreview="true"
                @upload="applicationForm = $event"
                @remove="applicationForm = null" />
            </v-flex>
            <v-flex xs12 mt-2 px-2>
              <v-btn class="primary" type="submit" :disabled="!isValid">{{ isEdit ? 'update' : 'create'}}</v-btn>
            </v-flex>
            <template v-if="isEdit">

              <v-flex xs12 mt-2 px-2>
                <hr>
                <h2>Premiums / deductibles</h2>
                <v-data-table
                  :headers="healthHeaders"
                  :items="healthTable"
                  :items-per-page-options="[25, 50, 100, { text:'All' , value: -1 }]"
                >
                  <v-progress-linear height="6" slot="progress" color="primary" indeterminate />
                  <template slot="items" slot-scope="props">
                    <td v-for="(header, index) in healthHeaders" :key="index">
                      <template v-if="header.value === 'age'">{{ props.item.age }}</template>
                      <template v-else>${{ parseFloat(props.item[header.value]).toFixed(2) }}</template>
                    </td>
                  </template>
                </v-data-table>
              </v-flex>
            </template>
          </v-layout>
          <v-layout row wrap v-if="type === 'fis'" key="fis">
            <v-flex xs12 sm8 md6 px-3>
              <v-text-field
                label="Interest rate"
                type="number"
                v-model="fis.annualInterestRate"
              />
            </v-flex>
            <v-flex xs12 sm8 md6 px-3>
              <v-text-field
                label="Investment term"
                type="number"
                v-model="fis.term"
                :rules="[v => !!v && v <= 25 || 'Term must be under 26 years']"
              />
            </v-flex>
            <v-flex xs12 sm8 md6 px-3>
              <v-autocomplete
                v-model="fis.interestPaymentFrequency"
                :items="paymentFrequency"
                item-value="value"
                item-text="text"
                label="Interest paid"
              />
            </v-flex>
            <v-flex xs12 sm8 md6 px-3>
              <v-text-field 
                v-model="fis.security"
                label="Security"
              />
            </v-flex>
            <v-flex xs12 sm8 md6 px-3>
              <v-text-field
                v-model="fis.location"
                label="Location"
              />
            </v-flex>
            <v-flex xs12 sm8 md6 px-3>
              <v-text-field
                v-model="fis.minimumInvestment"
                type="number"
                label="Minimum investment value"
              />
            </v-flex>
            <v-flex xs12 px-3>
              <v-checkbox
                v-if="isEdit"
                label="Update application form?"
                v-model="updateApplicationForm"
              />
            </v-flex>
            <v-flex xs12 sm8 md6 px-3>
              <v-autocomplete
                v-if="countries"
                v-model="fis.locations"
                :items="countries"
                style="max-height: 200px;min-height: 45px;overflow: auto;"
                class="mb-3"
                item-value="name"
                item-text="name"
                hide-details
                multiple
                small-chips
                deletable-chips
                label="Select available locations"
              />
              <v-btn
                class="elevation-0"
                color="white"
                @click="fis.locations = countries.map(country => country.name)">Select all</v-btn>
              <v-btn
                class="elevation-0"
                color="white"
                @click="fis.locations = []">Select none</v-btn>
            </v-flex>
            <v-flex xs12 px-3 mt-3 v-if="!isEdit || updateApplicationForm">
              <dropzone
                text="Drop Application form file or click to upload it"
                :type="['application/pdf']"
                :hidePreview="true"
                @upload="applicationForm = $event"
                @remove="applicationForm = null" />
            </v-flex>
            <v-flex xs12 mt-2 px-2>
              <v-btn class="primary" type="submit" :disabled="!isValid">{{ isEdit ? 'update' : 'create'}}</v-btn>
            </v-flex>
          </v-layout>
          <v-layout row wrap v-if="type === 'isa'" key="isa">
            <v-flex xs12 sm8 md6 px-3>
              <v-text-field
                label="Interest rate"
                type="number"
                v-model="isa.annualInterestRate"
              />
            </v-flex>
            <v-flex xs12 sm8 md6 px-3>
              <v-text-field
                label="Investment term"
                type="number"
                v-model="isa.term"
              />
            </v-flex>
            <v-flex xs12 sm8 md6 px-3>
              <v-autocomplete
                v-model="isa.interestPaymentFrequency"
                :items="paymentFrequency"
                item-value="value"
                item-text="text"
                label="Interest paid"
              />
            </v-flex>
            <v-flex xs12 sm8 md6 px-3>
              <v-text-field
                v-model="isa.minimumInvestment"
                type="number"
                label="Minimum investment value"
              />
            </v-flex>
            <v-flex xs12 sm8 md6 px-3>
              <v-text-field 
                v-model="isa.security"
                label="Security"
              />
            </v-flex>
            <v-flex xs12 mt-2 px-2>
              <v-btn class="primary" type="submit" :disabled="!isValid">{{ isEdit ? 'update' : 'create'}}</v-btn>
            </v-flex>
          </v-layout>
        </v-slide-y-transition>
      </v-form>
    </v-flex>
  </v-slide-y-transition>
</template>

<script>
  import jsonData from '@/countries.json';

  export default {
    components: {
      dropzone: () => import('@/components/Dropzone'),
    },
    data: () => ({
      err: '',
      success: '',
      healthOptionRules: [
        v => (!isNaN(v) || v === 'Full Cover' || v === 'N/A') || 'Value is not valid',
        v => !!v || 'Field is required',
      ],
      countries: jsonData,
      valid: false,
      isEdit: false,
      type: '',
      over: false,
      dropArea: false,
      types: [
        {
          text: 'Health insurance',
          value: 'health',
        },
        {
          text: 'FIS',
          value: 'fis',
        },
        {
          text: 'ISA',
          value: 'isa',
        },
      ],
      options: ['Full Cover', 'N/A'],
      provider: null,
      providers: [],
      name: '',
      premiumPreview: null,
      isActive: true,
      ranking: undefined,
      brochure: undefined,
      updateBrochure: false,
      updateApplicationForm: false,
      hasPremiums: false,
      applicationForm: undefined,
      healthInsurance: {
        limit: null,
        inpatient: null,
        outpatient: null,
        dental: null,
        maternity: null,
        deductible: null,
        premium: null,
        locations: [],
      },
      isa: {
        annualInterestRate: null,
        term: null,
        interestPaymentFrequency: '',
        security: '',
        minimumInvestment: null,
      },
      fis: {
        annualInterestRate: null,
        term: null,
        interestPaymentFrequency: '',
        security: '',
        location: '',
        minimumInvestment: null,
        locations: [],
      },
      paymentFrequency: [
        { text: 'Monthly', value: 0 },
        { text: 'Quarterly', value: 1 },
        { text: 'Bi Annually', value: 2 },
        { text: 'Annually', value: 3 },
        { text: 'Maturity', value: 4 },
      ],
      locations: ['UK', 'USA', 'JP', 'GER'],
      currencies: ['EUR', 'USD', 'GBP', 'YEN'],
    }),
    computed: {
      isValid() {
        if (!this.valid ||
          (!this.isEdit && !this.brochure) ||
          (this.isEdit && this.updateBrochure && !this.brochure)) return false;
        switch (this.type) {
          case 'health': {
            if (this.isEdit) {
              return (!this.dropArea || this.hasPremiums) &&
                (!this.updateApplicationForm || !!this.applicationForm);
            }
            return !!this.healthInsurance.premium && !!this.applicationForm;
          }
          case 'fis': {
            return this.isEdit ? !this.updateApplicationForm ||
              !!this.applicationForm : !!this.applicationForm;
          }
          case 'isa':
          default: {
            return true;
          }
        }
      },
      healthHeaders() {
        return this.healthInsurance.premiumHeaders ? [
          { value: 'age', text: 'Age' },
          ...this.healthInsurance.premiumHeaders.map(header => ({
            value: header,
            text: header,
          })),
        ] : [];
      },
      healthTable() {
        return this.healthInsurance.premiumRates ?
          this.healthInsurance.premiumRates.map(deductible => ({
            age: deductible.age,
            ...deductible.deductibles.reduce((acc, curr) => {
              const accumulated = { ...acc };
              accumulated[curr.value] = curr.amount;
              return accumulated;
            }, {}),
          })) : [];
      },
    },
    watch: {
      err(val) {
        if (val !== '') {
          setTimeout(() => { this.err = ''; }, 5000);
        }
      },
      dropArea(val) {
        if (!val) {
          this.healthInsurance.premium = undefined;
          this.hasPremiums = false;
        }
      },
    },
    methods: {
      setHealthPremiums(val) {
        this.healthInsurance.premium = val;
        this.hasPremiums = !!val;
      },

      updateCountries(arr)
      {
          this.healthInsurance.locations = [...this.healthInsurance.locations,...arr];
      },
      goBack() {
        if (this.$route.params.providerId) {
          this.$router.push(`/provider/${this.$route.params.providerId}/details`);
        } else {
          this.$router.push('/products');
        }
      },
      previewHealth() {
        this.showPreiew = true;
      },
      async submit() {
        const productId = this.$route.params.productId;
        let data = {
          provider: this.provider,
          name: this.name,
          type: this.type,
          isActive: this.isActive,
        };
        if (this.ranking) {
          data.ranking = this.ranking;
        }
        switch (this.type) {
          case 'health': {
            data = { ...this.healthInsurance, ...data };
            data.premiumRates = undefined;
            data.premiumHeaders = undefined;
            const formData = new FormData();
            Object.keys(data).forEach(key => formData.set(key, data[key]));
            if (!this.isEdit || this.updateApplicationForm) {
              formData.append('applicationForm', this.applicationForm);
            }
            data = formData;
            break;
          }
          case 'fis': {
            data = { ...this.fis, ...data };
            const formData = new FormData();
            Object.keys(data).forEach(key => formData.set(key, data[key]));
            if (!this.isEdit || this.updateApplicationForm) {
              formData.append('applicationForm', this.applicationForm);
            }
            data = formData;
            break;
          }
          case 'isa': {
            data = { ...this.isa, ...data };
            break;
          }
          default:
            this.err = 'Type is required';
            return;
        }
        let url;
        if (this.isEdit) {
          url = this.type === 'health' ? `product/${this.type}/${productId}` : `product/${productId}`;
        } else {
          url = `product/${this.type}`;
        }
        const method = this.isEdit ? 'put' : 'post';
        const res = await this.$webHelper[method](url, data, ['health', 'fis'].includes(this.type));
       

        if (!res.success) {
          this.err = 'Failed to submit request';
          return;
        }
        this.success = this.isEdit ? 'Product updated successfuly' : 'Product created successfuly';
       

        if (!this.isEdit || this.updateBrochure) {
          console.log("PID" +  res.body.id);
          await this.uploadBrochure(this.isEdit ? productId : res.body._id);
          return;
        }
        this.successRedirect(this.isEdit ? productId : res.body._id);
      },
      async uploadBrochure(id) {
        const url = `product/${id}/brochure`;
        const formData = new FormData();
        formData.append('brochure', this.brochure);
        const res = await this.$webHelper.post(url, formData, true);
        if (!res.success) {
          this.err = 'Failed to upload brochure';
          return;
        }
        this.successRedirect(id);
      },
      successRedirect(id) {
        setTimeout(() => {
          if (this.isEdit) {
            this.success = '';
          } else {
            const redirect = this.$route.params.providerId ?
              `/provider/${this.$route.params.providerId}/products/${id}` :
              `/products/${id}`;
            this.$router.push(redirect);
          }
        }, 3000);
      },
    },
    async created() {
      const providerRes = await this.$webHelper.get('provider');
      if (!providerRes.success) {
        this.err = 'Failed to fetch providers';
        return;
      }
      this.providers = providerRes.body;
      if (this.$route.params.providerId) {
        this.provider = this.$route.params.providerId;
      }
      if (this.$route.params.productId) {
        this.isEdit = true;
        var res = await this.$webHelper.get(`product/${this.$route.params.productId}`);
        //var res = await this.$webHelper.get(`product/health/${this.$route.params.productId}`);
        console.log(res);

        if (!res.success) {
          this.err = 'Failed to fetch Provider';
        }
        this.name = res.body.name;
        this.ranking = res.body.ranking;
        this.isActive = res.body.isActive;
        this.provider = typeof res.body.provider === 'string' ?
            res.body.provider :
            res.body.provider._id;

        console.log(res.body.type);

        switch (res.body.kind) {
          case 'isa': {
            this.isa = { ...res.body };
            this.type = 'isa';
            break;
          }
          case 'fis': {
            this.fis = { ...res.body };
            if (
              this.fis.locations &&
              this.fis.locations[0] &&
              this.fis.locations[0].includes(',')) {
              this.fis.locations = this.fis.locations[0].split(',');
            }
            this.type = 'fis';
            break;
          }
          case 'HealthProduct': {
            var resHealth = await this.$webHelper.get(`product/health/${this.$route.params.productId}`);

            this.healthInsurance = { ...resHealth.body };
            if (this.healthInsurance.locations[0].includes(',')) {
              this.healthInsurance.locations = this.healthInsurance.locations[0].split(',');
            }
            this.type = 'health';
            break;
          }
          default: {
            this.err = 'Missing product type';
          }
        }
      }
    },
  };
</script>

<style lang="scss" >
  .v-autocomplete {
    label {
      z-index: 1;
    }
  }
  .label-adj {
    .v-label {
      top: 12px !important;
    }
    .v-label--active {
      top: 6px !important;
    }
  }

  hr {
    display: block;
    height: 2px;
    border: 0;
    border-top: 2px solid #26a69a;
    margin: 1em 0;
    padding: 0;
  }

  .fade-transition {
    &-leave-active {
      position: absolute
    }
    &-enter-active, &-leave, &-leave-to {
      transition: all 225ms ease-in;
    }
    &-enter, &-leave-to {
      opacity: 0;
      transform: translateY(30px);

    }
  }
</style>
