<template>
  <v-slide-y-transition
    hide-on-leave
    leave-absolute>
    <v-flex xs12>
      <h2 class="mb-2">Products</h2>
      <v-flex xs12 v-if="err">
        <v-alert :value="true" type="error">{{err}}</v-alert>
      </v-flex>
      <v-card-title class="pb-4 px-0 layout row wrap align-end">
        <v-btn v-if='!life' @click="$router.push({ name: 'productCreate' })" class="primary mx-0 my-0">Create new</v-btn>
        <v-spacer />
        <v-text-field
          class="min-w max-w"
          v-model="search"
          append-icon="mdi-search"
          label="Search"
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="search"
        :loading="loading"
        :items-per-page-options="[10, 25, 50, 100, { text:'All' , value: -1 }]"
      >
        <v-progress-linear height="6" slot="progress" color="primary" indeterminate />
        <template v-slot:item.name="{ item }">
          <td class="table-min-w">{{ item.name }}</td>
        </template>
        <template v-slot:item.provider="{ item }">
          <td>{{ item.provider.name }}</td>
        </template>
        <template v-slot:item.type="{ item }">
          <td>{{ item.type }}</td>
        </template>
        <template v-slot:item.created="{ item }">
          <td>{{ formatDate(item.created) }}</td>
        </template>
        <template v-slot:item.isActive="{ item }">
          <v-switch
              hide-details
              v-model="item.isActive"
              @click.stop.prevent="changeStatus(item)"/>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn class="accent mx-2 elevation-1" @click="deleteProduct(item._id)">delete</v-btn>
          <v-btn class="primary mx-0 elevation-1" @click="$router.push({ name: `productDetails`, params: { productId: item._id } })">view</v-btn>
        </template>
        <v-alert slot="no-results" :value="true" color="warning" icon="warning">
          Your search for "{{ search }}" found no results.
        </v-alert>
      </v-data-table>
    </v-flex>
  </v-slide-y-transition>
</template>

<script>
  import { DateTime } from 'luxon';

  export default {
    data() {
      return {
        err: '',
        search: '',
        loading: false,
        headers: [
          { text: 'Product name', value: 'name', align: 'left' },
          { text: 'Provider', value: 'provider.name', align: 'left' },
          { text: 'Type', value: 'type', align: 'left' },
          { text: 'Created', value: 'created', align: 'left' },
          { text: 'Status', value: 'isActive', align: 'left' },
          { text: 'actions', value: 'actions', align: 'left', sortable: false },
        ],
        data: [],
        routeName: this.$route.name,
        life: false,
        type: '',
      };
    },
    watch: {
      err(val) {
        if (val !== '') {
          setTimeout(() => { this.err = ''; }, 5000);
        }
      },
      $route: {
        handler() {
          this.routeName = this.$route.name;
        },
        immediate: true,
      },
    },
    methods: {
      formatDate(date) {
        return DateTime.fromISO(date).toFormat('yyyy-MM-dd');
      },
      async changeStatus(product) {
        console.log(product);
        const res = await this.$webHelper.put(`product/${product._id}`, { isActive: product.isActive });
        if (!res.success) {
          this.err = 'Change status failed';
          return;
        }
        this.success = 'Status changed';
        this.$set(product, 'isActive', product.isActive);
      },
      async deleteProduct(id) {
        const res = await this.$webHelper.delete(`product/${id}`);
        if (!res.success) {
          this.err = 'Failed to delete product';
          return;
        }
        this.success = 'Product removed';
        const index = this.data.findIndex(product => product._id === id);
        this.data.splice(index, 1);
      }
    },
    async created() {
      const res = await this.$webHelper.get('product');
      if (res.success) {
        this.data = res.body;
        return;
      }
      this.err = 'Failed to fetch products';
    },
  };
</script>
