<template>
  <v-slide-y-transition 
    hide-on-leave 
    leave-absolute>
    <v-flex xs12 class="mx-2">
      <h2 class="mb-2">Providers</h2>
      <v-flex xs12 v-if="err">
        <v-alert :value="true" type="error">{{err}}</v-alert>
      </v-flex>
      <v-card-title class="pb-4 px-0 layout row wrap align-end"> 
        <v-btn v-if='!life' @click="$router.push({ name: 'providerCreate'})" class="primary mx-0 my-0">Create New {{provider}}</v-btn>
        <v-spacer />
        <v-text-field
          class="min-w max-w"
          v-model="search"
          append-icon="mdi-search"
          label="Search"
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="search"
        :loading="loading"
        :items-per-page-options="[10, 25, 50, 100, { text:'All' , value: -1 }]"
      >
        <v-progress-linear height="6" slot="progress" color="primary" indeterminate />
        <template v-slot:item.name="{ item }">
          <td class="table-min-w">{{ item.name }}</td>
        </template>
        <template v-slot:item.trustRanking="{ item }">
          <td>{{ item.trustRanking }}</td>
        </template>
        <template v-slot:item.url="{ item }">
          <td class="table-min-w">{{ item.url }}</td>
        </template>
        <template v-slot:item.isActive="{ item }">
          <td>
            <v-switch
              hide-details
              v-model="item.isActive"
              @click.stop.prevent="changeStatus(item)"/>
          </td>
        </template>
        <template v-slot:item.actions="{ item }">
          <td>
            <v-btn class="accent mx-2 elevation-1" @click="deleteProvider(item.id)">delete</v-btn>
            <v-btn class="primary mx-0 elevation-1" @click="$router.push({ name: 'providerDetails', params: { id: item.id } })">view</v-btn>
          </td>
        </template>
        <v-alert slot="no-results" :value="true" color="warning" icon="warning">
          Your search for "{{ search }}" found no results.
        </v-alert>
      </v-data-table>
    </v-flex>
  </v-slide-y-transition>
</template>

<script>
  export default {
    data() {
      return {
        err: '',
        search: '',
        loading: false,
        headers: [
          { text: 'Provider name', value: 'name', align: 'left' },
          { text: 'Trust ranking', value: 'trustRanking', align: 'left' },
          { text: 'Website', value: 'url', align: 'left' },
          { text: 'Status', value: 'isActive', align: 'left' },
          { text: 'Actions', value: 'actions', align: 'left', sortable: false },
        ],
        data: [],
        provider: '',
        routeName: this.$route.name,
        life: false,
        type: '',
      };
    },
    watch: {
      err(val) {
        if (val !== '') {
          setTimeout(() => { this.err = ''; }, 5000);
        }
      },
      $route: {
        handler() {
          this.routeName = this.$route.name;
        },
        immediate: true,
      },
    },
    methods: {
      async changeStatus(provider) {
        const res = await this.$webHelper.put(`provider/${provider.id}`, { isActive: provider.isActive });
        if (!res.success) {
          this.err = 'Change status failed';
          return;
        }
        this.success = 'Status changed';
        this.$set(provider, 'isActive', provider.isActive);
      },
      async deleteProvider(id) {
        const res = await this.$webHelper.delete(`provider/${id}`);
        if (!res.success) {
          this.err = 'Failed to delete provider';
          return;
        }
        this.success = 'Provider removed';
        const index = this.data.findIndex(provider => provider.id === id);
        this.data.splice(index, 1);
      },
    },
    async created() {
      const res = await this.$webHelper.get('provider');
      if (res.success) {
        console.log(res.body);
        this.data = res.body;
        return;
      }
      this.err = 'Failed to fetch providers';
    },
  };
</script>
