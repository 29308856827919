<template>
  <v-slide-y-transition
    hide-on-leave 
    leave-absolute>
    <v-flex xs12 class="mx-3">
      <h2 class="mb-2 px-3">
        Provider
        <v-btn
          color="primary"
          fab
          small
          class="elevation-0"
          @click="$router.push({ name: 'providers' })">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </h2>
      <v-flex xs12 px-3 pb-3 v-if="err">
        <v-alert type="error" :value="true">{{err}}</v-alert>
      </v-flex>
      <v-flex xs12 px-3 pb-3 v-if="success">
        <v-alert type="success" :value="true">{{success}}</v-alert>
      </v-flex>
      <v-form v-model="valid">
        <v-layout row wrap class="w-100">
          <v-flex xs12 sm6 md6 px-3>
            <v-text-field
              v-model="data.name"
              label="Name"
              required
              :rules="[v => !!v || ' Name is required']"
            />
            <v-text-field
              v-model="data.contactName"
              label="Contact name"
              required
              :rules="[v => !!v || 'Contact name is required']"
            />
            <v-text-field
              v-model="data.contactAddress"
              label="Contact email"
              required
              :rules="emailRules"
            />
            <v-layout row wrap>
              <v-checkbox
                label="Activate"
                v-model="data.isActive"
                class="mx-1"
              />
              <v-checkbox
                label="Has regulation"
                v-model="data.regulation"
                class="mx-1"
              />
            </v-layout>
          </v-flex>
          <v-flex xs12 sm6 md6 px-3>
            <v-text-field
              label="Trust ranking"
              v-model="data.trustRanking"
              type="number"
              step="0.1"
              min="0"
              max="5"
              hint="Value must be between 0 and 5"
              persistent-hint
              required
              :rules="[v => (data.trustRanking > 0 && data.trustRanking <= 5 && (`${data.trustRanking}`.length <= 4)) || 'Trust rankig is not valid']"
            />
            <v-text-field
              v-model="data.contactPhone"
              label="Contact phone"
              required
              :rules="[v => !!v || 'Contact phone is required']"
            />
            <v-text-field
              v-model="data.url"
              label="Website"
              required
              :rules="[v => !!v || 'Website is required']"
            />
            <v-text-field
              v-if="data.regulation"
              label="Regulation location"
              v-model="data.regulationLocation"   
            />
          </v-flex>
          <v-flex xs12 px-3>
            <v-select
              multiple
              small-chips
              deletable-chips
              v-model="data.showOn"
              :items="showOn"
              label="Show provider logo on"
              required
            />
          </v-flex>
          <v-flex xs12 px-3>
            <dropzone
              :type="['image/jpeg', 'image/png']"
              text="Drop image here or click to upload the logo"
              :current="{
                value: image,
                type: 'image',
              }"
              @upload="imageFile = $event"
              @remove="imageFile = null"/>
            <v-btn @click="submit" class="primary mx-0" :disabled="!valid">
              {{ isEdit? 'Update' : 'Create' }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-form>
      <v-flex v-if="data.products" xs12 px-3>
        <h3 class="mb-1 mt-3">
          Products
          <v-btn
            color="primary"
            fab
            small
            class="elevation-0"
            @click="$router.push({ name: 'productCreateForProvider', params: { providerId: $route.params.id } })">
            <v-icon>mdi-add</v-icon>
          </v-btn>

        </h3>
        <v-data-table
          :headers="headers"
          :items="data.products"
          :search="search"
          :items-per-page-options="[10, 25, 50, 100, { text:'All' , value: -1 }]">
          <v-progress-linear height="6" slot="progress" color="primary" indeterminate />
          <template slot="items" slot-scope="props">
            <td>{{ props.item.name }}</td>
            <td>{{ formatDate(props.item.created) }}</td>
            <td>
              <v-switch
              hide-details
              :input-value="props.item.isActive"
              @click.stop.prevent="changeStatus(props.item)"/>
            </td>
            <td>
              <v-btn
                class="primary mx-0 elevation-1"
                @click="$router.push(`/provider/${$route.params.id}/products/${props.item._id}`)">edit</v-btn>
            </td>
          </template>
          <v-alert slot="no-results" :value="true" color="warning" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>

          <v-layout slot="no-data" justify-center align-center>
            This provider has no products.
            <v-btn color="primary" @click="$router.push({ name: 'productCreateForProvider', params: { providerId: $route.params.id } })">Create one?</v-btn>
          </v-layout>
        </v-data-table>
      </v-flex>
    </v-flex>
  </v-slide-y-transition>
</template>

<script>
  import { DateTime } from 'luxon';

  export default {
    data() {
      return {
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
        err: '',
        success: '',
        valid: false,
        search: '',
        isEdit: false,
        showOn: [
          {
            text: 'Homepage',
            value: 'homepage',
          },
          {
            text: 'Health insurance',
            value: 'health',
          },
          {
            text: 'Life insurance',
            value: 'life',
          },
          {
            text: 'ISA',
            value: 'isa',
          },
          {
            text: 'FIS',
            value: 'fis',
          },
          {
            text: 'Pensions',
            value: 'pensions',
          },
        ],
        data: {
          showOn: [],
          name: '',
          isActive: true,
          trustRanking: null,
          contactName: '',
          contactPhone: '',
          contactAddress: '',
          regulation: false,
          regulationLocation: '',
          url: '',
          products: undefined,
        },
        imageFile: null,
        image: null,
        loading: true,
        headers: [
          { text: 'Product name', value: 'name', align: 'left' },
          { text: 'Created', value: 'created', align: 'left' },
          { text: 'Status', value: 'isActive', align: 'left' },
          { text: 'Actions', value: 'actions', align: 'left', sortable: false },
        ],
      };
    },
    watch: {
      err(val) {
        if (val !== '') {
          setTimeout(() => { this.err = ''; }, 5000);
        }
      },
      regulation() {
        this.regulationLocation = '';
      },
    },
    methods: {
      formatDate(date) {
        return DateTime.fromISO(date).toFormat('yyyy-MM-dd');
      },
      async changeStatus(product) {
        let status;
        if (product.isActive === true) {
          status = 'inactive';
        } else {
          status = 'active';
        }
        // eslint-disable-next-line
        const res = await this.$webHelper.put(`product/${product._id}/status`, { status });
        if (!res.success) {
          this.err = 'Change status failed';
          return;
        }
        this.success = `${product.name} status changed`;
        this.$set(product, 'isActive', status === 'active');
      },
      async submit() {
        const formData = new FormData();
        Object.keys(this.data).forEach((key) => {
          formData.set(key, this.data[key]);
        });
        if (this.imageFile) {
          formData.set('logo', this.imageFile);
        }
        const url = this.isEdit ? `provider/${this.$route.params.id}` : 'provider';
        const method = this.isEdit ? 'put' : 'post';
        const res = await this.$webHelper[method](url, formData, true);
        if (!res.success) {
          if (res.status >= 500) {
            this.err = 'Internal server error';
            return;
          }
          const [error] = Object.keys(res.body);
          switch (error) {
            case 'name': {
              this.err = 'Invalid name';
              break;
            }
            case 'isActive': {
              this.err = 'Invalid activation value';
              break;
            }
            case 'contactName': {
              this.err = 'Invalid contact name';
              break;
            }
            case 'contactPhone': {
              this.err = 'Invalid contact phone';
              break;
            }
            case 'contactAddress': {
              this.err = 'Invalid contact address';
              break;
            }
            case 'url': {
              this.err = 'Invalid url';
              break;
            }
            case 'regulation': {
              this.err = 'Invalid regulation';
              break;
            }
            case 'trustRanking': {
              this.err = 'Invalid trust ranking';
              break;
            }
            case 'logo': {
              this.err = 'Invalid logo';
              return;
            }
            default: {
              this.err = 'Unknown error';
            }
          }
          return;
        }
        if (res.success) {
          this.success = this.isEdit ? 'Provider updated successfuly' : 'Provider created successfuly';
          setTimeout(() => {
            if (this.isEdit) {
              this.success = '';
            } else {
              this.$router.push({ name: 'providerDetails', params: { id: res.body.id } });
            }
          }, 3000);
        }
      },
      getLogoUrl(url) {
        return process.env.NODE_ENV === 'development' ? `http://localhost:3000/${url}` : `/${url}`;
      },
    },
    async created() {
      if (this.$route.params.id) {
        this.isEdit = true;
        const res = await this.$webHelper.get(`provider/${this.$route.params.id}`);
        if (!res.success) {
          this.err = 'Failed to fetch Provider';
        }
        const data = res.body;
        if (data.contactName) {
          this.contactName = data.contactName;
        }
        if (data.contactPhone) {
          this.contactPhone = data.contactPhone;
        }
        if (data.contactAddress) {
          this.contactAddress = data.contactAddress;
        }
        if (data.regulation) {
          this.regulation = data.regulation;
        }
        if (data.logo) {
          this.image = this.getLogoUrl(data.logo);
        }
        if (data.url) {
          this.url = data.url;
        }
        if (data.regulationLocation && data.regulation === true) {
          this.regulationLocation = data.regulationLocation;
        }
        this.data = data;
      }
    },
    components: {
      dropzone: () => import('@/components/Dropzone'),
    },
  };
</script>

<style lang="scss" scoped>
  .message-img {
    max-width: 85%;
    margin: 0 auto;
    overflow: hidden;
    height: auto;
    padding-top: 60%;
    position: relative;
    border: 2px dashed rgba(0,0,0, 0.12);
    &:hover {
      cursor: pointer;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      max-width: 100%;
      height: auto;
    }
  }
</style>
